import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import TwoColDisplay from "../../components/two-col-display"

const JeffPage = () => {
  const query = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "profile-jeff" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const node = query.allFile.edges[0].node
  const image = node.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Grandpa Jeff" />
      <div className="grid grid-cols-1 items-center justify-center w-full">
        <div className="pt-4 flex flex-grow justify-center items-center">
          <div className="max-w-4xl">
            <TwoColDisplay image={image}>
              <div className="text-center">
                <h2 className="font-rubik text-lg">
                  @too old for instagram :(
                </h2>
                <div className="px-8 grid grid-cols-1 gap-4 py-4 text-xl">
                  <div>
                    Jeff is 1000 years old and was Cy’s mentor many moons ago.
                    Jeff loves turtles and the devil’s lettuce. He can do any
                    tattoo you want as long as it’s after 5pm Monday, Wednesday,
                    Friday, or all day Sunday
                  </div>

                  {/* <div>Availability W,TH,F,S,S</div> */}
                  <div>Can't Connect with Jeff on Instagram</div>
                </div>
              </div>
            </TwoColDisplay>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JeffPage
